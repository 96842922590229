import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[inputMoney]'
})
export class MoneyDirective implements OnInit {

    ngOnInit(): void {
        if(this._el.nativeElement.value){
            this._el.nativeElement.value = parseInt(this._el.nativeElement.value).toLocaleString('vi-VN')
        }
    }

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    let value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== value) {
      event.stopPropagation();
    }
    if(value != ""){
        this._el.nativeElement.value = parseInt(value).toLocaleString('vi-VN')
    }
    else this._el.nativeElement.value = value;
  }

}