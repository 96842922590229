<ng-template #defaultItemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item {{item.checked ? 'active' : ''}}">
    <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa" [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"></i>
    <div class="form-check ">
<!--      <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"-->
<!--        [disabled]="item.disabled" [indeterminate]="item.indeterminate" />-->
      <label class="text" (click)="item.checked = !item.checked; onCheckedChange()" [nbTooltip]="item.text">
        {{item.text}}
      </label>
    </div>
    <button nbButton size="tiny" class="ml-auto" ghost [nbContextMenu]="menus" *ngIf="showContextMenu">
      <nb-icon [icon]="'more-vertical-outline'"></nb-icon>
    </button>
  </div>
</ng-template>
<ng-template #defaultHeaderTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="row-filter">
      <input class="form-control" type="text" [placeholder]="i18n.getFilterPlaceholder()" [(ngModel)]="filterText"
        (ngModelChange)="onFilterTextChange($event)" />
  </div>
  <div *ngIf="hasFilterItems">
    <div *ngIf="config.hasAllCheckBox || config.hasCollapseExpand" class="row row-all">
      <div class="col-12">
        <div class="form-check form-check-inline" *ngIf="config.hasAllCheckBox">
          <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
            [indeterminate]="item.indeterminate" />
          <span class="text" (click)="item.checked = !item.checked; onCheckedChange()">
            {{i18n.getAllCheckboxText()}}
          </span>
        </div>
        <label *ngIf="config.hasCollapseExpand" class="pull-right label" (click)="onCollapseExpand()">
          <i [title]="i18n.getTooltipCollapseExpandText(item.collapsed)" aria-hidden="true" class="fa"
            [class.fa-expand]="item.collapsed" [class.fa-compress]="!item.collapsed"></i>
        </label>
      </div>
    </div>
    <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
  </div>
</ng-template>
<div class="treeview-header">
  <ng-template [ngTemplateOutlet]="headerTemplate || defaultHeaderTemplate" [ngTemplateOutletContext]="headerTemplateContext">
  </ng-template>
</div>
<div [ngSwitch]="hasFilterItems">
  <div *ngSwitchCase="true" class="treeview-container example-boundary" [style.max-height.px]="maxHeight" cdkDropListGroup >
    <ngx-treeview-item [hasDrag]="hasDrag" (dropChange)="drop($event)" *ngFor="let item of filterItems" [config]="config" [item]="item" [template]="itemTemplate || defaultItemTemplate"
      (checkedChange)="onItemCheckedChange(item, $event)" >
    </ngx-treeview-item>
  </div>
  <div *ngSwitchCase="false" class="treeview-text">
    {{i18n.getFilterNoItemsFoundText()}}
  </div>
</div>
