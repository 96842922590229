import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { createRequestOption } from "../../shared/util/request-util";
import { ReportModel } from "../model/report.model";

@Injectable()
export class ReportService {

  constructor(private http: HttpClient) {
  }

  public insert(model: any): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-reports`, model, {
      observe: 'response'
    });
  }

  public clone(model: any, id: any): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-reports?oldReportId=${ id }`, model, {
      observe: 'response'
    });
  }

  public update(model: any): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-reports/update`, model, {
      observe: 'response'
    });
  }

  public delete(id: any): Observable<any> {
    return this.http.delete(`${ environment.apiUrl }/rp-reports/${ id }`);
  }

  public getGroupRpReportByProgramId(dataSearch: any): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-reports/groups`, dataSearch);
  }

  public getAllRpReportByProgramId(dataSearch: any): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-reports/all`, dataSearch);
  }

  public getCountRpReportById(id: any): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-reports/getCountUsed/${ id }`);
  }

  public getDetailRpReport(programId: any, reportType: any): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-reports/detail/${ programId }/${ reportType }`);
  }

  public getDetailTreeRpReport(idReport: any, prdId: any): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-reports/detailTree/${ idReport }/${ prdId }`);
  }

  public getRpReportById(id: any): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-reports/${ id }`);
  }

  public updateStatusRpReport(id: any, status: number): Observable<any> {
    return this.http.put(`${ environment.apiUrl }/rp-reports/update-status/${ id }`, status, {
      observe: 'response'
    });
  }

  getWithGranted(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${ environment.apiUrl }/rp-reports/get-with-granted`, {
      params: options,
      observe: 'response'
    });
  }

  getDataRpTableOfContents(params): Observable<any> {
    return this.http.get<any[]>(`${ environment.apiUrl }/rp-object-idx`, {
      params: params,
    });
  }

  postDataRpTableOfContents(params): Observable<any> {
    return this.http.post<any[]>(`${ environment.apiUrl }/rp-object-idx/add`, params
    );
  }

  getParentData(id): Observable<any> {
    return this.http.get<any[]>(`${ environment.apiUrl }/rp-object-idx/parent?objId=${ id }`)
  }

  deleteData(id): Observable<any> {
    return this.http.delete(`${ environment.apiUrl }/rp-object-idx/delete/${ id }`)
  }

  editData(params): Observable<any> {
    return this.http.put(`${ environment.apiUrl }/rp-object-idx/update`, params)
  }

  saveTemplate(params): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-object-idx/save-template`, params)
  }

  getImplementing(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-object-org?objId=${ id }`)
  }

  getLeverUnit(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-objidx-orgtype?idxId=${ id }`)
  }

  deleteDataImplement(id): Observable<any> {
    return this.http.delete(`${ environment.apiUrl }/rp-object-org/delete/${ id }`)
  }

  addDataImplement(params): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-object-org/add`, params)
  }

  addLeverUnit(orgTypes, reportId): Observable<any> {
    const param = {
      reportId: reportId,
      orgCode: orgTypes
    }
    return this.http.post(`${ environment.apiUrl }/update-org-code`, param)
  }

  getInsert(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/update-org-code?reportId=${ id }`)
  }

  getImplementingAgencies(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp_objidx_org?reportId=${ id }`)
  }

  addDecentralization(params): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp_objidx_org/save`, params)
  }

  getImplementingAgenciesLever(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-objidx-orgtype?reportId=${ id }`)
  }

  addDecentralizationLever(params): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp-objidx-orgtype/save`, params)
  }

  saveDataInput(params): Observable<any> {
    return this.http.post(`${ environment.apiUrl }/rp_objidx_data/save`, params)
  }

  getDataInput(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp_objidx_data?grant_id=${ id }`)
  }
  getDataTableParameter(id): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/rp-doc-forms/${ id }`)
  }

  onDropTree(data): Observable<any> {
    data.forEach(e => delete e.editor);
    return this.http.put(`${ environment.apiUrl }/rp-object-idx/update-order`, data)
  }

  searchDataAllImplement(req?: any, body?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.post<any[]>(`${ environment.apiUrl }/sys-organizations/query-not-assigned`, body, {
      params: options,
      observe: 'response'
    });
  }

  dowloadTemplate(body): Observable<any> {
    console.log(body)
    return this.http.post(`${ environment.apiUrl }/rp_objidx_data/download?fileName=Test.docx`, body, {
      observe: 'response',
      responseType: 'arraybuffer'
    })
  }
}
