import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { getCurrentUser } from '../../utils/common';
import { RoleService } from '../role.service';
import { UsersService } from '../users.service';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
    constructor(
        private userService: UsersService,
        private roleService: RoleService
    ) { }
    roleChange$: ReplaySubject<string> = new ReplaySubject<string>(1);
    currRole: string;


    getUserRole() {
        const id = getCurrentUser().id;
        this.userService.getUserById(id).subscribe(res => {
            const roleId = Number(res.body.roleId);
            this.roleService.getAllRole().subscribe(resRole => {
                const myRole = resRole.body.find(r => r.id == roleId);
                if (myRole) {
                    this.setRole(myRole.code)
                    this.currRole = myRole.code;
                }
            })
        })
    }

    setRole(roleCode: string) {
        this.roleChange$.next(roleCode);
    }
}