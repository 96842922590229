import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Hotel } from '../../@core/model/hotel-management/hotel.model';
import { UserRoleService } from '../../@core/service/hotel-management/user-role.service';
import { ROLE_HOST } from '../../shared/constants/constants';

@Directive({ selector: '[bypassTimeCheck]' })
export class BypassTimeCheckDirective implements OnChanges {
    constructor(
        private el: ElementRef,
        private userRoleService: UserRoleService
    ) { 
        this.userRole = this.userRoleService.currRole;
    }
    
    @Input() bypassTimeCheck: Hotel;
    private userRole: string;

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['bypassTimeCheck']){
            if(this.userRole == ROLE_HOST && this.bypassTimeCheck && this.bypassTimeCheck.bypassTimeCheck){
                (<HTMLInputElement>this.el.nativeElement).removeAttribute('disabled');
            } else {
                (<HTMLInputElement>this.el.nativeElement).setAttribute('disabled', 'disabled');
            }
        }
    }
}