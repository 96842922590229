<div *ngIf="item" class="treeview-item ul-tree"  cdkDropList (cdkDropListDropped)="drop($event)"  [cdkDropListData]="item"  style="margin-left: 20px">
  <div cdkDrag [cdkDragData]="item"   [cdkDragDisabled]="item?.value?.isDefault || !hasDrag">
    <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item, onCollapseExpand: onCollapseExpand, onCheckedChange: onCheckedChange}">
    </ng-template>
    <div class="example-custom-placeholder" *cdkDragPlaceholder>
      <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item, onCollapseExpand: onCollapseExpand, onCheckedChange: onCheckedChange}">
      </ng-template>
    </div>
  </div>

</div>
<div *ngIf="!item.collapsed"  class="ul-tree" style="margin-left: 35px">
  <ngx-treeview-item [hasDrag]="hasDrag" (dropChange)="onDrop($event)" [config]="config" *ngFor="let child of item.children" [item]="child" [template]="template" (checkedChange)="onChildCheckedChange(child, $event)">
  </ngx-treeview-item>
</div>
