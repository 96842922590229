import {Injectable} from '@angular/core';
import { Observable } from "rxjs";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import 'rxjs/add/operator/do';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (request.url.startsWith('http') && !(environment.apiUrl && request.url.startsWith(environment.apiUrl)))) {
      return next.handle(request);
    }
    const token = localStorage.getItem('httpHeaders');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
          'Accept-Language': 'vi'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'vi'
        }
      });
    }
    return next.handle(request).do((event: any) => {
      if (event instanceof HttpResponse) {
        localStorage.setItem('httpHeaders', event.headers.get('Authorization'));
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 0) {
          console.log('1.auth');
          localStorage.clear();
          this.router.navigate(['auths/login']);
        }
      }
    });
  }
}
