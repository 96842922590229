import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService
} from '@nebular/theme';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {Page} from '../../../@core/model/page.model';
import {NotificationService} from '../../../@core/service/notification.service';
import {LoginService} from "../../../@core/service/login.service";
import {TranslateService} from '@ngx-translate/core';
import { UserRoleService } from '../../../@core/service/hotel-management/user-role.service';
import { ROLE_SYSADMIN } from '../../../shared/constants/constants';
import { UsersService } from '../../../@core/service/users.service';
import { openBlob } from '../../../@core/utils/common';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userName: any;
  img: any;
  window = window;

  themes = [
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  currentTheme = 'cosmic';

  roleAction = null;
  page = new Page();

  menuClick(e) {
    this.roleAction = e.role;
    if (e.menuId === 3) {
      console.log('5.header');
      localStorage.clear();
      this.router.navigate(['auths/login']);
    } else if (e.menuId === 2) {
      this.router.navigate(['auths/changePass'], {queryParams: {userName: this.userName}});
    }
    else if(e.menuId == 1){
      this.userAPIService.downloadFOTA().subscribe(res => {
        openBlob(res.body, 'docmodbusESPFull.ino.bin');
      })
    }
  }

  userMenu = [
    // {menuId: 1, title: 'Thông tin cá nhân'},
    {menuId: 2, title: 'Đổi mật khẩu'},
    {menuId: 3, title: 'Đăng xuất'},
  ];
  adminMenu = [
    {menuId: 1, title: 'Tải file FOTA'},
    {menuId: 2, title: 'Đổi mật khẩu'},
    {menuId: 3, title: 'Đăng xuất'},
  ]
  totalMessage: any;
  listData: any;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private toastrService: NbToastrService,
              private breakpointService: NbMediaBreakpointsService,
              public notificationService: NotificationService,
              private translateService: TranslateService,
              private userLogin: LoginService,
              private userAPIService: UsersService,
              private userRoleService: UserRoleService,
              private router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.search(0)
    this.user = localStorage.getItem('users');
    this.img = JSON.parse(localStorage.getItem('userDetails'))['imageUrl'];
    // this.userName = JSON.parse(localStorage.getItem('userDetails')).userName;
    this.currentTheme = this.themeService.currentTheme;
    this.userRoleService.roleChange$.pipe(takeUntil(this.destroy$)).subscribe(roleCode => {
			// if(roleCode == ROLE_SYSADMIN){
      //   this.userMenu = [...this.adminMenu];
      // }
		})
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
    this.menuService.onItemClick().subscribe((event) => {
      this.menuClick(event.item);
    });
    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // getUserName() {
  //   this.userLogin.getUserLogin().subscribe(res => {
  //     this.userName = res.body;
  //   })
  // }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  search(page: number) {
    this.page.pageNumber = page;
    this.notificationService.query({
      msgStatus: 1,
      page: this.page.pageNumber,
      size: this.page.size
    }).subscribe(res => {
      this.onSuccess(res.body, res.headers, this.page.pageNumber)
    })
  }

  protected onSuccess(data: any | null, headers: HttpHeaders, page: number): void {
    this.page.totalElements = Number(headers.get('X-Total-Count'));
    this.page.totalPages = page || 0;
    this.listData = data
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  openSiderbar(){
    this.sidebarService.expand('menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  navigate() {
    this.router.navigate(['/pages/notification/notification-manager'])
  }

  readCheck(selected) {
    const id = selected.map(e => e.id)
    this.notificationService.check({
      msgIds: id,
      msgStatus: 0
    }).subscribe(data => {
        // this.toastrService.show('Đánh dấu đã đọc thành công', `Thông báo`, {status: 'success'});
        this.search(0);
      },
      (error) => {
        this.toastrService.show(error.error.message, this.translateService.instant('common.label.notice'), {status: 'warning'});
      }
    )
  }

  detail(row: any) {
    this.readCheck([row])
    this.router.navigate(['/pages/report/manager'], {
      queryParams: {
        stateId: row.stateId,
        reportCode: row.reportCode,
        programId: row.programId
      }
    });
  }
}

export function checkRole() {
  return true;
}


