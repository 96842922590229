import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {createRequestOption} from "../../shared/util/request-util";
import { Hotel } from '../model/hotel-management/hotel.model';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {
  }

  public doSearch(req?: any, body?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.post<any[]>(`${environment.apiUrl}/users/doSearch`, body, {
      params: options,
      observe: 'response'
    });
  }

  public getUserById(userId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/${userId}`, {
      observe: 'response'
    });
  }

  public update(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/update`, data, {
      observe: 'response'
    });
  }

  public insert(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/insert`, data, {
      observe: 'response'
    });
  }

  public delete(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/delete`, data, {
      observe: 'response'
    });
  }

  getHotelsByUser(){
    return this.http.get<Partial<Hotel>[]>(`${environment.apiUrl}/hotels/getHotelByUser`, {
      observe: 'response'
    });
  }

  downloadFOTA(){
    return this.http.get(`${environment.apiUrl}/download`, {
      observe: 'response',
      responseType: "blob"
    });
  }

  getHotelsByAdmin(){
    return this.http.get<Partial<Hotel>[]>(`${environment.apiUrl}/hotels/getHotel`, {
      observe: 'response'
    });
  }

}
