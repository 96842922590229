import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule, NbCardModule, NbPopoverModule, NbListModule, NbTooltipModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SafePipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {RestApiService} from '../@core/mock/rest-api.service';
import {ToastrService} from '../@core/mock/toastr-service';
import {AuthGuardService} from '../@core/mock/auth-guard.service';
import {RoleService} from '../@core/service/role.service';
import {DeptService} from '../@core/service/dept.service';
import {UsersService} from '../@core/service/users.service';
import {ActionService} from '../@core/service/action.service';
import {ModuleService} from '../@core/service/module.service';
import {ShareService} from '../@core/mock/share.service';
import {AuthGuardAuthsService} from '../@core/mock/auth-guard-auths.service';
import {RoleModuleService} from '../@core/service/role-module.service';
import {ModuleActionService} from "../@core/service/module-action.service";
import {ReportRecurringService} from '../@core/service/report-recurring.service'
import {RouterModule} from '@angular/router';
import {
  DefaultTreeviewEventParser,
  DefaultTreeviewI18n,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n
} from 'ngx-treeview';
import {ReportService} from "../@core/service/rp-report.service";
import {RpProgramService} from "../@core/service/rp-program.service";
import { BreadcrumbComponent } from './layouts/bread-crumb/bread-crumb.component';
import {TranslateModule} from '@ngx-translate/core';
import { BypassTimeCheckDirective, MoneyDirective, NumberDirective, PermissionDirective } from './directives';
const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbTooltipModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SafePipe
];
const DIRECTIVES = [
  NumberDirective,
  MoneyDirective,
  PermissionDirective,
  BypassTimeCheckDirective
]
const SERVICE = [
    RestApiService,
    ToastrService,
    AuthGuardService,
    UsersService,
    RoleService,
    DeptService,
    ActionService,
    ReportService,
    RpProgramService,
    ModuleService,
    RoleModuleService,
    ModuleActionService,
    AuthGuardAuthsService,
    [TreeviewConfig,
      {provide: TreeviewI18n, useClass: DefaultTreeviewI18n},
      {provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser},
    ],
    ShareService,
  ReportRecurringService,
  ]
;

@NgModule({
    imports: [CommonModule, ...NB_MODULES, NbCardModule, NbPopoverModule, NbListModule, RouterModule, TranslateModule],
    exports: [CommonModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES],
    declarations: [...COMPONENTS, ...PIPES, BreadcrumbComponent, ...DIRECTIVES],
    providers: [...SERVICE],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
