import { Hotel } from "../model/hotel-management/hotel.model";
import { OrderTypeType } from "../model/hotel-management/room-order.model";
import { RoomType } from "../model/hotel-management/room-type.model";
import * as moment from "moment";

/**
 * return Date object with timeCheck (in minute) that saved in DB
 */
export function getTimeCheckInDate(timeCheck: number) {
  const hour = Math.floor(timeCheck / 60);
  const min = timeCheck - hour * 60;
  const dateObj = new Date();
  dateObj.setHours(hour);
  dateObj.setMinutes(min);
  return dateObj;
}

//#region NEW CALCULATION
const OneDay = 24 * 60;

const isSameDate = (date1: Date, date2: Date) => {
  return date1.getDate() == date2.getDate() && date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth();
}

const substractDate = (d2: Date, d1: Date) => {
  let cloneD1 = new Date(d1), cloneD2 = new Date(d2);
  cloneD1.setHours(0, 0, 0, 0);
  cloneD2.setHours(0, 0, 0, 0);
  return moment(cloneD2).diff(cloneD1, "days");
}

// sử dụng trong trường hợp lowLimit luôn hiểu là của ngày hôm trước, k có uplimit
const getRentByDay2 = (checkin: Date, checkout: Date, config: Partial<Hotel> & Partial<RoomType>) => {
  /**
   * số phút checkin sớm
   */
  let soonCheckinMin = 0;
  /**
   * tiền checkin sớm
   */
  let amountSoonCheckin = 0;
  /**
   * tiền checkout muộn
   */
  let amountLateCheckout = 0;
  let minCheckin = getMinuteOfDate(checkin);
  let minCheckout = getMinuteOfDate(checkout) - config.delayTimePayment;
  let lateCheckoutMin = 0; // ko có trường hợp vừa checkin sớm vừa checkout muộn
  let numOfDatesStay = 0;
  if (minCheckin < config.lowLimitDay) {
    soonCheckinMin = config.lowLimitDay - minCheckin;
  }
  if (minCheckout > config.checkoutExpireDay && !isSameDate(checkin, checkout)) {
    lateCheckoutMin = minCheckout - config.checkoutExpireDay;
    amountLateCheckout = Math.ceil(lateCheckoutMin / config.unitSoonLateDay) * config.pricePerUnitSoonLateDay;
  }
  if (isSameDate(checkin, checkout)) {
    numOfDatesStay = 1;
  }
  else {
    numOfDatesStay = substractDate(checkout, checkin);
  }
  amountSoonCheckin = Math.ceil(soonCheckinMin / config.unitSoonLateDay) * config.pricePerUnitSoonLateDay;
  return config.priceDay * numOfDatesStay + amountSoonCheckin + amountLateCheckout;
}

export const getMinuteOfDate = (date: Date): number => {
  return date.getHours() * 60 + date.getMinutes();
}

const min = Math.min, ceil = Math.ceil;

const getRentByDay3 = (checkin: Date, checkout: Date, config: Partial<Hotel> & Partial<RoomType>) => {
  const checkinMin = getMinuteOfDate(checkin), checkoutMin = getMinuteOfDate(checkout) - config.delayTimePayment, { priceDay, unitSoonLateDay, pricePerUnitSoonLateDay, lowLimitDay, checkoutExpireDay } = config;
  if(isSameDate(checkin, checkout)){
    // CI trước mốc, CO trc mốc
    if(checkinMin < lowLimitDay && checkoutMin <= checkoutExpireDay) return priceDay;
    // CI trc mốc, CO sau mốc
    if(checkinMin < lowLimitDay && checkoutMin >= checkoutExpireDay) {
      let soonCheckinMin = lowLimitDay - checkinMin, lateCheckoutMin = checkoutMin - checkoutExpireDay;
      return priceDay + min(priceDay, pricePerUnitSoonLateDay * Math.ceil(Math.min(soonCheckinMin, lateCheckoutMin) / unitSoonLateDay));
    }
    // CI sau mốc, CO sau mốc
    if(checkinMin >= lowLimitDay && checkoutMin >= checkoutExpireDay) return priceDay;
    // CI sau mốc, CO trc mốc (được ở từ 12h ngày n đến 15h ngày n+1 => vô lý nhưng vẫn xảy ra)
    else return priceDay;
  } else {
    const checkinOutSubstraction = substractDate(checkout, checkin);
    // CI trc, CO trc
    // MIN(CI sớm ngày n; 1D) + MIN(CO muộn ngày m-1; 1D) + (m-n-1)*1D;   
    // (m-n+1)*1D; 
    // CI đúng ngày n-1 (1D) + (m-n-1)*1D + MIN(CO muộn ngày m-1; 1D)
    if(checkinMin <= lowLimitDay && checkoutMin <= checkoutExpireDay){
      let amountSoonCheckin = pricePerUnitSoonLateDay * Math.ceil((lowLimitDay - checkinMin)/unitSoonLateDay);
      let amountLateCheckoutDayBefore = pricePerUnitSoonLateDay * Math.ceil((checkoutMin + OneDay - checkoutExpireDay)/unitSoonLateDay);
      let totalOne = min(priceDay, amountSoonCheckin) + min(amountLateCheckoutDayBefore, priceDay) + (checkinOutSubstraction-1)*priceDay;
      let totalTwo = (checkinOutSubstraction+1)*priceDay;
      let totalThree = checkinOutSubstraction*priceDay + min(amountLateCheckoutDayBefore, priceDay);
      return min(totalOne, totalTwo, totalThree);
    }
    
    // CI trc, CO sau
    // MIN(CI sớm ngày n; 1D) + MIN(CO muộn ngày m; 1D) + (m-n)*1D; 
    // CI đúng ngày n-1 (1D) + (m-n)*1D + MIN(CO muộn ngày m; 1D)
    if(checkinMin <= lowLimitDay && checkoutMin >= checkoutExpireDay){
      let amountSoonCheckin = pricePerUnitSoonLateDay * Math.ceil((lowLimitDay - checkinMin)/unitSoonLateDay);
      let amountLateCheckout = pricePerUnitSoonLateDay * Math.ceil((checkoutMin - checkoutExpireDay)/unitSoonLateDay);
      const totalOne = min(amountSoonCheckin, priceDay) + min(amountLateCheckout, priceDay) + checkinOutSubstraction*priceDay;
      const totalThree =(checkinOutSubstraction + 1)*priceDay + min(amountLateCheckout, priceDay);
      return min(totalOne, totalThree);
    }

    // CI sau, CO trc
    // (m-n)*1D
    if(checkinMin >= lowLimitDay && checkoutMin <= checkoutExpireDay){
      return checkinOutSubstraction*priceDay;
    }

    // CI sau, CO sau
    // MIN(CO muộn; 1D) + (m-n)*1D
    if(checkinMin >= lowLimitDay && checkoutMin >= checkoutExpireDay){
      let amountLateCheckout = pricePerUnitSoonLateDay * Math.ceil((checkoutMin - checkoutExpireDay)/unitSoonLateDay);
      return checkinOutSubstraction*priceDay + min(amountLateCheckout, priceDay);
    }

  }
}

/**
 * - CHỈ DÙNG CÔNG THỨC NÀY KHI checkin checkout cùng ngày, và cả 2 cùng không nằm trong khoảng đêm
 * - Hoặc công thức đêm bị disable, khi đó công thức này sẽ tính lan sang được các ngày tiếp theo
 */
const getRentByHourInDay = (checkin: Date, checkout: Date, config: Partial<Hotel> & Partial<RoomType>) => {
  const {
    firstRangeHour,
    priceFirstRangeHour,
    unitSecondRangeHour,
    pricePerUnitSecondRangeHour,
    unitExtraHour,
    pricePerUnitExtraHour
  } = config;
  const checkinMin = getMinuteOfDate(checkin);
  const checkoutMin = getMinuteOfDate(checkout) - config.delayTimePayment;
  let total = 0;
  if (checkoutMin - checkinMin <= firstRangeHour && isSameDate(checkin, checkout)) {
    total = priceFirstRangeHour;
  }
  else {
    const unitsSecondRange = Math.floor((checkoutMin + OneDay*substractDate(checkout, checkin) - checkinMin - firstRangeHour) / unitSecondRangeHour);
    const unitsExtra = Math.ceil((checkoutMin + OneDay*substractDate(checkout, checkin) - checkinMin - firstRangeHour - unitsSecondRange * unitSecondRangeHour) / unitExtraHour);
    total = priceFirstRangeHour + pricePerUnitSecondRangeHour * unitsSecondRange + pricePerUnitExtraHour * unitsExtra;
  }
  return total;
}

/**
 * áp dụng cho giờ tối. CHỈ phụ thuộc checkout time có nằm trong khoảng ngưỡng giờ tối không
 */
const getRentByHourInShortNight = (checkin: Date, checkout: Date, config: Partial<Hotel> & Partial<RoomType>) => {
  // đã vào trong hàm này thì đã chắc chắn checkout time nằm trong khoảng giờ tối (short night)
  let {
    lowLimitNight,
    unitShortNight,
    pricePerUnitShortNight,
    unitExtraShortNight,
    pricePerUnitExtraShortNight,
    pricePerUnitSoonLateNight,
    unitSoonLateNight
  } = config;
  let checkinMin = getMinuteOfDate(checkin);
  let checkoutMin = getMinuteOfDate(checkout) - config.delayTimePayment;
  if (checkoutMin < checkinMin) return pricePerUnitShortNight; // vừa vào chưa ở được n (phút), n = config.delayTimePayment
  let total: number = 0;
  // checkin sớm (trước giới hạn trên giờ đêm)
  if (checkinMin < lowLimitNight) {
    const unitsSoon = Math.ceil((lowLimitNight - checkinMin) / unitSoonLateNight);
    const unitsStayShortNight = Math.floor((checkoutMin - lowLimitNight) / unitShortNight);
    if (unitsStayShortNight == 0) {
      total = pricePerUnitShortNight + unitsSoon * pricePerUnitSoonLateNight;
    }
    else {
      const unitsExtra = Math.ceil((checkoutMin - lowLimitNight - unitsStayShortNight * unitShortNight) / unitExtraShortNight);
      total = unitsSoon * pricePerUnitSoonLateNight + unitsStayShortNight * pricePerUnitShortNight + unitsExtra * pricePerUnitExtraShortNight;
    }
  }
  // checkin đúng giờ (sau giới hạn trên giờ đêm)
  else if (checkoutMin - checkinMin > 0) {
    const unitsStayShortNight = Math.floor((checkoutMin - checkinMin) / unitShortNight);
    if (unitsStayShortNight == 0) {
      total = pricePerUnitShortNight;
    }
    else {
      const unitsExtra = Math.ceil((checkoutMin - checkinMin - unitsStayShortNight * unitShortNight) / unitExtraShortNight);
      total = unitsStayShortNight * pricePerUnitShortNight + unitsExtra * pricePerUnitExtraShortNight;
    }
  }
  return total;
}

/**
 * Khách chỉ được ở từ lúc đến cho tới cùng giờ ngày hôm sau
 */
const getRentByLongDay = (checkin: Date, checkout: Date, config: Partial<Hotel> & Partial<RoomType>) => {
  const {
    unitLateCheckoutLongDay,
    pricePerUnitLateCheckoutLongDay,
    priceOneLongDay
  } = config;
  let total: number = 0;
  // loại này k có checkin sớm
  // chênh lệch checkin và checkout
  let checkinMin = getMinuteOfDate(checkin);
  let checkoutMin = getMinuteOfDate(checkout) - config.delayTimePayment;
  let unitsLateCheckout: number = 0, numOfDatesStay: number = substractDate(checkout, checkin);
  // cùng ngày
  if (isSameDate(checkin, checkout)) return priceOneLongDay;
  else {
    // nếu không ở tròn 24h kể từ h thứ 25 trở đi, chắc chắn có phát sinh
    if(checkoutMin <= checkinMin){
      if(numOfDatesStay > 1){
        unitsLateCheckout = Math.ceil((checkoutMin + OneDay - checkinMin)/unitLateCheckoutLongDay);
        numOfDatesStay--; // vì đã lấy 24h cho OneDay bên trên
      }
      // nếu chỉ ở trong vòng <=24h, chỉ mất tiền 1 ngày 24h ---> k làm gì cả!!!
    } else {
      unitsLateCheckout = Math.ceil((checkoutMin - checkinMin)/unitLateCheckoutLongDay);
    }
  }
  const priceForLateCheckout = unitsLateCheckout * pricePerUnitLateCheckoutLongDay;
  // so sánh tiền phát sinh với tiền ở 1 ngày 24h, cái nào nhỏ hơn thì lấy
  total = numOfDatesStay * priceOneLongDay + (priceForLateCheckout > priceOneLongDay ? priceOneLongDay: priceForLateCheckout);
  return total;
}

const getRentByHourInNight = (checkin: Date, checkout: Date, config: Partial<Hotel> & Partial<RoomType>) => {
  // checkin nằm trong
  // checkout nằm trong
  // cả checkin và checkout nằm trong
  // checkin <lower < upper < checkout
  let {
    lowLimitNight,
    upLimitNight,
    checkoutExpireNight,
    unitNight, // 1 đêm
    pricePerUnitNight, // giá 1 đêm
    unitExtraNight, // 1 đêm
    pricePerUnitExtraNight, // giá 1 đêm luôn
    unitSoonLateNight,
    pricePerUnitSoonLateNight
  } = config;
  let checkinMin = getMinuteOfDate(checkin);
  let checkoutMin = getMinuteOfDate(checkout) - config.delayTimePayment;
  //#region biến đổi phù hợp với công thức mới
  unitNight = checkoutExpireNight + OneDay - lowLimitNight;
  pricePerUnitExtraNight = pricePerUnitNight;
  unitExtraNight = unitNight;
  //#endregion
  let total = 0;
  // cùng ngày
  if (isSameDate(checkin, checkout)) {
    // cả checkin và checkout cùng nằm trong
    if (checkinMin >= lowLimitNight && checkinMin <= upLimitNight + OneDay && checkoutMin >= lowLimitNight && checkoutMin <= upLimitNight + OneDay) {
      const unitsNight = Math.floor((checkoutMin - checkinMin) / unitNight);
      const unitsExtra = Math.ceil((checkoutMin - checkinMin - unitsNight * unitNight) / unitExtraNight);
      total = unitsNight * pricePerUnitNight + unitsExtra * pricePerUnitExtraNight;
    }
    // checkin sớm và checkout trong khoảng thời gian giờ đêm
    else if (checkoutMin >= lowLimitNight && checkoutMin <= upLimitNight + OneDay && checkinMin < lowLimitNight) {
      const unitsNight = Math.floor((checkoutMin - lowLimitNight) / unitNight);
      const unitsSoon = Math.ceil((lowLimitNight - checkinMin) / unitSoonLateNight);
      const unitsExtra = Math.ceil((checkoutMin - lowLimitNight - unitsNight * unitNight) / unitExtraNight);
      total = unitsNight * pricePerUnitNight + unitsSoon * pricePerUnitSoonLateNight + unitsExtra * pricePerUnitExtraNight;
    }
    // checkin trong khoảng giờ đêm (từ 0h sáng) và checkout nằm ngoài khoảng h đêm (checkout muộn)
    else if (checkinMin < upLimitNight && checkoutMin > checkoutExpireNight) {
      const unitsNight = 1;
      const unitsLate = Math.ceil((checkoutMin - checkoutExpireNight) / unitSoonLateNight);
      total = unitsNight * pricePerUnitNight + unitsLate * pricePerUnitSoonLateNight;
    }
    else {
      total = pricePerUnitNight;
    }
  }
  // khác ngày
  else {
    let checkinOutSubstraction = substractDate(checkout, checkin);
    /**
     * khoảng thời gian ở từ uplimit đến lowlimit của các ngày
     */
    let numOfMidDays = 0;
    if(
      (checkinMin >= lowLimitNight && checkoutMin >= lowLimitNight) || // CI, CO thuộc N1
      (checkinMin > upLimitNight && checkinMin < lowLimitNight && checkoutMin >= lowLimitNight) ||  // CI thuộc N3, CO thuộc N1
      (checkinMin <= upLimitNight && checkoutMin < lowLimitNight) // CI thuộc N2, CO thuộc N2 hoặc N3
    ) {
      numOfMidDays = checkinOutSubstraction;
    } else if(
      ((checkinMin >= lowLimitNight || (checkinMin > upLimitNight && checkinMin < lowLimitNight)) && (checkoutMin <= upLimitNight || (checkoutMin > upLimitNight && checkoutMin < lowLimitNight)))
    ) {
      numOfMidDays = checkinOutSubstraction-1;
    }
    else {
      numOfMidDays = checkinOutSubstraction+1;
    }
    const fulldayOfExtraUnits = Math.ceil(numOfMidDays*( lowLimitNight - checkoutExpireNight )/unitSoonLateNight);
    /**
     * - nếu khách ở từ ngày n, đến ngày m, sẽ có 1 khoảng từ checkoutExpireNight đến lowLimitNight được tính full là giờ phát sinh của ngày đó, khi đến lowLimitNight, lại quay về giờ đêm
     * - nếu m=n+1, giá trị này sẽ bằng 0
     */
    /**
     * (1) trong trường hợp checkin nằm trong: 
     * - nếu checkin sáng sớm ngày n đến ngày m, thì số đêm = m-n + 1 (1h sáng mùng 2 ---> 10h sáng mùng 5 => 4 đêm (2,3,4,5))
     * - nếu checkin tối ngày n-1 đên ngày m, thì số đêm = m-(n-1) (23h mùng 1 ---> 10h sáng mùng 5 => 4 đêm (2,3,4,5))
     */
    // checkin trong khoảng đêm, checkout ngoài khảong đêm
    if (insideARange(upLimitNight, lowLimitNight, checkinMin) && !insideARange(upLimitNight, lowLimitNight, checkoutMin)) {
      const unitsNight = checkinMin > lowLimitNight? checkinOutSubstraction: (checkinOutSubstraction + 1); // (1)
      // checkout đúng giờ
      if (checkoutMin <= checkoutExpireNight) {
        total = unitsNight * pricePerUnitNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
      }
      // checkout muộn
      else {
        const unitsLate = Math.ceil((checkoutMin - checkoutExpireNight) / unitSoonLateNight);
        total = unitsNight * pricePerUnitNight + unitsLate * pricePerUnitSoonLateNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
      }
    }
    // checkout nằm trong, checkin nằm ngoài
    // vừa checkin sớm, checkout đúng h hoặc muộn
    else if (insideARange(upLimitNight, lowLimitNight, checkoutMin) && !insideARange(upLimitNight, lowLimitNight, checkinMin)) {
      const unitsNight = checkinOutSubstraction;
      const unitsSoon = Math.ceil((lowLimitNight - checkinMin) / unitSoonLateNight);
      // CO thuộc N1
      if(checkoutMin >= lowLimitNight){
        total = (checkinOutSubstraction + 1)*pricePerUnitNight + unitsSoon * pricePerUnitSoonLateNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
      }
      // CO thuộc N2
      else {
        total = checkinOutSubstraction * pricePerUnitNight + unitsSoon * pricePerUnitSoonLateNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
      }
    }
    // cả checkin và checkout cùng nằm trong khoảng
    // checkin đúng h, checkout muộn hoặc checkout vào tối 
    else if (
      insideARange(upLimitNight, lowLimitNight, checkoutMin) && insideARange(upLimitNight, lowLimitNight, checkinMin)
    ) {
      if(checkinMin >= lowLimitNight){ // CI thuộc N1
        if(checkoutMin >= lowLimitNight){ // CO thuộc N1
          total = (checkinOutSubstraction + 1)*pricePerUnitNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
        } else { // CO thuộc N2
          total = checkinOutSubstraction*pricePerUnitNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
        }
      } else { // CI thuộc N2
        if(checkoutMin >= lowLimitNight){ // CO thuộc N1
          total = (checkinOutSubstraction + 2)*pricePerUnitNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
        } else { // CO thuộc N2
          total = (checkinOutSubstraction + 1)*pricePerUnitNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
        }
      }
    }
    // cả checkin và checkout cùng Không nằm trong khoảng
    // sẽ xảy ra nếu như ở khác ngày
    else if (!insideARange(upLimitNight, lowLimitNight, checkoutMin) && !insideARange(upLimitNight, lowLimitNight, checkinMin)) {
      const unitsNight = checkinOutSubstraction
      const unitsSoon = Math.ceil((lowLimitNight - checkinMin) / unitSoonLateNight);
      total = unitsNight * pricePerUnitNight + unitsSoon * pricePerUnitSoonLateNight + fulldayOfExtraUnits*pricePerUnitSoonLateNight;
      // checkout muộn
      if (checkoutMin > checkoutExpireNight) {
        const unitsLate = Math.ceil((checkoutMin - checkoutExpireNight) / unitSoonLateNight);
        total += unitsLate * pricePerUnitSoonLateNight;
      }
    }
  }
  return total;
}

/**
 * NOTE: only correct when checkin in hour at night
 */
const insideARange = (upLimit: number, lowLimit: number, time: number) => {
  return (time >= lowLimit) || (time <= upLimit)
}

const isShortNightConfigured = (config: Partial<Hotel> & Partial<RoomType>) => {
  return config.limitShortNight > config.lowLimitNight && config.pricePerUnitShortNight > 0;
}

export const getRentInfo2 = (checkinTime: Date, checkoutTime: Date, config: Partial<Hotel> & Partial<RoomType>, orderType: Partial<OrderTypeType>) => {
  const actualMinutesRent = Math.round((checkoutTime.getTime() - checkinTime.getTime()) / 60000);
  const hours = actualMinutesRent / 60;
  const rentByDay2 = getRentByDay3(checkinTime, checkoutTime, config);
  switch (orderType) {
    case 1:
      if (config.useLongDay) {
        return {
          totalMoney: getRentByLongDay(checkinTime, checkoutTime, config),
          actualMinutesRent,
          hours
        }
      }
      return {
        totalMoney: rentByDay2,
        actualMinutesRent,
        hours
      }
    default:
      const finalDayCalculation = config.useLongDay ? getRentByLongDay(checkinTime, checkoutTime, config) : rentByDay2;
      const checkoutMin = getMinuteOfDate(checkoutTime) - config.delayTimePayment;
      const checkinMin = getMinuteOfDate(checkinTime);
      if (config.useNightCalculation && 
        (
          insideARange(config.upLimitNight, config.lowLimitNight, checkinMin) 
          || insideARange(config.upLimitNight, config.lowLimitNight, checkoutMin) 
          || !isSameDate(checkinTime, checkoutTime)
        )
      ) {        
        // nếu bật flexible time và checkin checkout time cùng ngày và 
        // checkin không thuộc khoảng sau giờ đêm (đầu ngày từ 0h), checkout cùng thuộc giờ đêm (khoảng cuối ngày đến 0h), thì sẽ luôn luôn sử dụng công thức tính giờ theo ngày
        if(
          isSameDate(checkinTime, checkoutTime) 
          && config.flexibleCheckoutTime 
          && ( checkinMin > config.upLimitNight && checkoutMin >= config.lowLimitNight)
        ){
          const rentByHourInDay = getRentByHourInDay(checkinTime, checkoutTime, config);
          return {
            totalMoney: rentByHourInDay > finalDayCalculation ? finalDayCalculation : rentByHourInDay,
            actualMinutesRent,
            hours
          }
        }
        // chỉ sử dụng giờ tối khi checkout cùng tối đó, trong ngưỡng tgian đã được config, NẾU CÓ
        // và flexibleCheckoutTime = false (ngưỡng bắt đầu giờ đêm không phụ thuộc vào thời gian checkout của khách)
        if (isShortNightConfigured(config) && isSameDate(checkinTime, checkoutTime) && (checkoutMin <= config.limitShortNight && checkoutMin > config.lowLimitNight) && !config.flexibleCheckoutTime) {
          const rentByShortNight = getRentByHourInShortNight(checkinTime, checkoutTime, config);
          return {
            totalMoney: rentByShortNight > finalDayCalculation ? finalDayCalculation: rentByShortNight,
            actualMinutesRent,
            hours
          }
        }
        const rentByHourInNight = getRentByHourInNight(checkinTime, checkoutTime, config);

        return {
          totalMoney: rentByHourInNight > finalDayCalculation ? finalDayCalculation : rentByHourInNight,
          actualMinutesRent,
          hours
        }
      }
      else {
        const rentByHourInDay = getRentByHourInDay(checkinTime, checkoutTime, config);
        return {
          totalMoney: rentByHourInDay > finalDayCalculation ? finalDayCalculation : rentByHourInDay,
          actualMinutesRent,
          hours
        }
      }
  }
}

//#endregion