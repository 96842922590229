import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Directive({ selector: '[hasPermission]' })
export class PermissionDirective implements OnChanges {
    constructor(
        private router: Router,
        private el: ElementRef,
    ) { 
    }

    @Input() hasPermission: string;

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['hasPermission']){
            let currentRoute = this.router.url;
            let currentModule = JSON.parse(localStorage.getItem('objects')).find(obj => obj.link == currentRoute);
            if(currentModule && Array.isArray(currentModule.role)){
                let myActions = currentModule.role;
                if(!this.checkIncludePermission(myActions)){
                    // this.el.nativeElement.style.display = 'none'
                    this.el.nativeElement.remove();
                }
            }
        }
    }

    private checkIncludePermission(myPermissions: any[]){
        const allowPers = this.hasPermission.split(',').map(p => p.toLowerCase().trim());
        let isAllowed = false;
        myPermissions.forEach(p => {
            if(allowPers.find(act => act == p.codeAction.toLowerCase())){
                isAllowed = true;
            }
        })
        return isAllowed;
    }
}