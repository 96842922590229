<!--<div class="dropdown" ngxDropdown>-->
<!--  <button [disabled]="disable" class="btn" [ngClass]="buttonClass" type="button" role="button"-->
<!--          ngxDropdownToggle>          {{getText()}}        </button>-->
<!--  <div ngxDropdownMenu aria-labelledby="dropdownMenu" (click)="$event.stopPropagation()">-->
<!--    <div class="dropdown-container">-->
      <ngx-treeview #treeviewComponent [config]="config" [headerTemplate]="headerTemplate" [items]="items" [showContextMenu]="showContextMenu"
                    [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)"
                    (filterChange)="onFilterChange($event)"></ngx-treeview>
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
