import { Base } from "./base.model";
import { RoomType } from "./room-type.model";

export class Hotel extends Base {
    hotelName: string;
    hotelCode: string;
    email: string;
    cellphone: string;
    /**
     * amount of rooms
     */
    amountRoom: number;
    /**
     * 2h10 = 2h, if this equals to 10
     */
    delayTimePayment: number;
    /**
     * time for cleaning
     */
    cleanTime: number;
    /**
     * max times of turning on the light
     */
    numTurnLight: number;
    /**
     * domain to use with apis
     */
    domainUrl: string;
    roomTypes: Partial<RoomType>[];
    /**
     * after this moment (in hour), room_order will be set to rentByDay
     */
    timeCheck: number;
    /**
     * time allowed to cancel checkin 
     */
    noChangeLimitTime: number;
    /**
     * - 0 - call from BE
     * - 1 - call from FE
     */
    configCallApi: 0 | 1;
    /**
     * time light is on when turn on temperary (in minute)
     */
    lightOnTime: number;
    /**
     * auto refresh time in dashboard page (in second)
     */
    reloadTime: number;

    botToken: string;

    chatTeleId: string;

    teleStatus: boolean;

    bypassTimeCheck: boolean;
}

