export class Base {
    id: number;
    createdDate: string;
    createdDateObj: Date;
    createdBy: string;
    updateDate: string;
    updateDateObj: Date;
    updateBy: string;
    description: string;
    status: number;
}