import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable()
export class ReportRecurringService {
  constructor(private http: HttpClient) {
  }

  public getListReportMode(data): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/report-recurring/get-list-report`, data, {
      observe: 'response'
    });
  }
  public getOrgId(data): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/rp-input-grants/get-org-id`, data, {
      observe: 'response'
    });
  }

  public getColumn(data): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/report-recurring/get-column`, data, {
      observe: 'response'
    });
  }

  public getDatarow(data): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/rp-datarows/getAllDataRpRecurring`, data, {
      observe: 'response'
    });
  }
  public getReportMode(): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/report-recurring/get-report-mode`, {}, {
      observe: 'response'
    });
  }


}
