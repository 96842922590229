export const Constants = {
  ReportState: {
    GRANTED: 1, // đã giao
    DELIVERED: 2, // đã trình lãnh đạo
    SENT: 3, // đã được gửi
    APPROVED: 4, // đã phê duyệt
    REJECT: 5, // từ chối cấp đơn vị
    NEED_UPDATED: 6, // cần đính chính
    INPUTING: 7, // nhập liệu
    REJECT_BY_SUPERIOR: 8, // từ chối cấp đơn vị giao
    NOT_COMPLETED: 10, // không hoàn thành
    CANCELED: 11, // đã hủy giao
  },
  ApplyOrgTypes: {
    ASSIGN_ORG: 2, // đơn vị giao
    INPUT_ORG: 1, // đơn vị thực hiện
    BOTH: 3 // cả 2
  },
  RollbackActionCode: 'LAY_LAI'
}

export const TimeTypes = {
  TimeType: {
    DOT_XUAT: '1',
    THANG: '2',
    QUY: '3',
    NAM: '4'
  }
}

export const SYSADMIN_USERNAME = "sysadmin";
//#region this must be changed if editting permission code
export const ROLE_SYSADMIN = "ADMIN_KS"; 
export const ROLE_HOST = "HOST_KS"; 
export const ROLE_RECEPTIONIST = "RECEPTIONIST_KS"; 
//#endregion
export const DEFAULT_TIME_CHECK_IN_DATE = (() => {
  const curr = new Date();
  curr.setHours(23);
  curr.setMinutes(0);
  return curr;
})();
export const DEFAULT = {
  NUM_OF_ROOM: '100',
  TIME_CHECK: DEFAULT_TIME_CHECK_IN_DATE,
  DELAY_TIME_PAYMENT: '10',
  CLEAN_TIME: '15',
  NUM_TURN_LIGHT: '3',
  NO_CHARGE_LIMIT_TIME: '15',
  LIGHT_ON_TIME: '10',
  RELOAD_TIME: '60',
  LOWER_LIMIT_DAY: 8*60,
  UP_LIMIT_DAY: 20*60,
  UNIT_SOON_LATE_DAY: 30,
  CHECKOUT_EXPIRE_DAY: 9*60,
  FIRST_RANGE_HOUR: 2*60,
  UNIT_SECOND_RANGE_HOUR: 60,
  UNIT_EXTRA_HOUR: 60,
  LOW_LIMIT_NIGHT: 22*60,
  UP_LIMIT_NIGHT: 5*60,
  UNIT_EXTRA_NIGHT: 30,
  UNIT_SOON_LATE_NIGHT: 30,
  CHECKOUT_EXPIRE_NIGHT: 9*60,
  UNIT_NIGHT: 60,
  ORDER_TYPE: 0,
  LIMIT_SHORT_NIGHT: 0,
  UNIT_SHORT_NIGHT: 0,
  UNIT_EXTRA_SHORT_NIGHT: 0,
  UNIT_LATE_LONG_DAY: 30,
  FLEXIBLE_CHECKOUT_TIME: 23*60+59
}



/**
 * actions that can be executed with a room object
 */
export const ACTIONS_ROOM = {
    EDIT_ROOM: "Cập nhật",
    VIEW_ROOM: "Xem phòng",
    EDIT_ORDER: "Sửa checkin",
    VIEW_ORDER: "Xem checkin",
    TURNON_LIGHT: "Bật điện",
    TURNOFF_LIGHT: "Tắt điện",
    CLEAN_ROOM: "Dọn phòng",
    CANCEL_ROOM: "Hủy phòng",
    MOVE_TO_OTHER: "Chuyển phòng",
    DELETE_ROOM: "Xóa phòng",
    /*
    * permission same as turnoff_light
    */
    GUEST_GO_OUT: "Khách ra ngoài",
    /*
    * permission same as turnon_light
    */
    GUEST_COME_BACK: "Khách quay về",
}

/**
 * status API IoT receive
 */
export const STATUS_API_RECEIVE = {
  ON: 'ON',
  OFF: 'OFF',
  DP: 'DP',
  ON_TEMP: 'ON_TEMP',
  LIGHT_STATUS: 'null',
  OFFLINE: 'Offline'
}

export const StorageConst = {
  CurrHotelKey: 'currHotel'
}

/**
 * delay time in millisec to set room'light status
 */
export const DELAY_SET_LIGHT_STATUS = 2000;

export const MQTT_AUTHEN = {
  username: "",
  password: ""
}

export const MQTT_LIGHT_STATUS = {
  RELAY_ON: 'RELAY_ON',
  RELAY_OFF: 'RELAY_OFF',
  KEY_RETURN: 'RSP',
  OFFLINE: 'OFFLINE',
  ON_OK: 'ON_OK',
  ON_FAIL: 'ON_FAIL',
  OFF_OK: 'OFF_OK',
  OFF_FAIL: 'OFF_FAIL',
}

export const STATUS_LIGHT_BACKEND = {
  ON: "1",
  OFF: "0",
  OFFLINE: "2",
  UNSET: null
}

export const MQTT_LIGHT_CMD = {
  ON: 'ON',
  OFF: 'OFF'
}