import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ConfRpProgram} from '../model/conf-rp-program.model';
import {createRequestOption} from '../../shared/util/request-util';

type EntityResponseType = HttpResponse<ConfRpProgram>;

@Injectable({
  providedIn: 'root'
})
export class RpProgramService {
  constructor(protected http: HttpClient) {

  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<ConfRpProgram[]>(`${environment.apiUrl}/rp-programs`, {
      params: options,
      observe: 'response'
    });
  }

  create(objModel: any): Observable<EntityResponseType> {
    return this.http.post<ConfRpProgram>(`${environment.apiUrl}/rp-programs`, objModel, {observe: 'response'});
  }

  update(objModel: any): Observable<EntityResponseType> {
    return this.http.post<ConfRpProgram>(`${environment.apiUrl}/rp-programs-update`, objModel, {observe: 'response'});
  }

  downloadFile(dataForm: any): Observable<any> {
    const options = createRequestOption(dataForm);
    return this.http.post(`${environment.apiUrl}/rp-programs/download`, null, {
      params: options,
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  delete(id: any) {
    return this.http.delete(`${environment.apiUrl}/rp-programs/${id}`);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/rp-programs/${id}`, {observe: 'response'});
  }

  getAllRpProgramByStatus(status: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/rp-programs/status/${status}`, {observe: 'response'});
  }
  getAllRpProgram(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/rp-programs/find-all`, {observe: 'response'});
  }
}
