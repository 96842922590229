<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange">
  <div class="text-nowrap row-item d-flex"
  [ngClass]="{'active': value?.value === item?.value || value === item?.value, 'disable': item.value.isHidden}"
  >
    <i (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed" [ngClass]="{'hidden': !item?.children}">
<!--      <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"-->
<!--           fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path-->
<!--          d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />-->
<!--      </svg>-->
      <nb-icon  icon="arrow-ios-forward-outline" *ngSwitchCase="true" class="mr-2 ml-2"></nb-icon>
      <nb-icon  icon="arrow-ios-downward-outline" *ngSwitchCase="false" class="mr-2 ml-2"></nb-icon>
<!--      <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"-->
<!--           fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path-->
<!--          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />-->
<!--      </svg>-->
    </i>
    <nb-icon icon="folder-outline" *ngIf="item?.children?.length > 0 && showIcon" class="mr-2 ml-2"></nb-icon>
    <nb-icon icon="file-text-outline" *ngIf="!item?.children?.length && showIcon" class="mr-2 ml-2"></nb-icon>
    <div class="text text-truncate" (click)="select(item, item.value.isHidden)" style="cursor: pointer; width: 100%" [nbTooltip]="item.text">{{item.text}}</div>
    <div class="ml-auto" [hidden]="item?.value?.idxForm && item?.value?.idxForm !== 0">
      <button nbButton size="tiny" [hidden]="item?.value?.canNotup"  ghost *ngIf="hasDrag && item.value.idx !== 1 && !item.value.isDefault && !item.value.isAfterDefault" (click)="moveItem('up', item.value)">
        <nb-icon [icon]="'arrow-ios-upward-outline'"></nb-icon>
      </button>
      <button [hidden]="item?.value?.canNotDown" nbButton size="tiny" ghost *ngIf="hasDrag && item?.value?.isBottom !== true && !item.value.isDefault" (click)="moveItem('down', item.value)">
        <nb-icon [icon]="'arrow-ios-downward-outline'"></nb-icon>
      </button>
      <button [hidden]="item?.value?.idxForm && item?.value?.idxForm !== 0" nbButton size="tiny" ghost [nbContextMenuPlacement]="'bottom-start'" [nbContextMenu]="item.value.menu" *ngIf="showContextMenu && item?.value?.menu && item?.value?.menu.length > 0" [nbContextMenuTag]="title + item.value.id" >
        <nb-icon [icon]="'more-vertical-outline'"></nb-icon>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #headerTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="row row-filter">
    <div class="col-12">
      <input class="form-control" type="text" [placeholder]="i18n.getFilterPlaceholder()" [(ngModel)]="filterText"
             (ngModelChange)="onFilterTextChange($event)" />
    </div>
  </div>
  <div *ngIf="config.hasAllCheckBox || config.hasCollapseExpand" class="row">
    <div class="col-12">
      <span *ngIf="config.hasAllCheckBox" (click)="select(item, item.value.isHidden) ">
        <strong>{{ i18n.getAllCheckboxText() }}</strong>
      </span>
      <label *ngIf="config.hasCollapseExpand" class="float-right" (click)="onCollapseExpand()">
        <i [title]="i18n.getTooltipCollapseExpandText(item.collapsed)" aria-hidden="true" [ngSwitch]="item.collapsed">
          <svg *ngSwitchCase="true" style="width: 1em;height: 1em" viewBox="0 0 16 16" class="bi bi-arrows-angle-expand"
               fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M1.5 10.036a.5.5 0 0 1 .5.5v3.5h3.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z" />
            <path fill-rule="evenodd"
                  d="M6.354 9.646a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0zm8.5-8.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
                  d="M10.036 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 1 1-1 0V2h-3.5a.5.5 0 0 1-.5-.5z" />
          </svg>
          <svg *ngSwitchCase="false" style="width: 1em;height: 1em" viewBox="0 0 16 16" class="bi bi-arrows-angle-contract"
               fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M9.5 2.036a.5.5 0 0 1 .5.5v3.5h3.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z" />
            <path fill-rule="evenodd"
                  d="M14.354 1.646a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 1 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0zm-7.5 7.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
                  d="M2.036 9.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V10h-3.5a.5.5 0 0 1-.5-.5z" />
          </svg>
        </i>
      </label>
    </div>
  </div>

  <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
</ng-template>
<div class="position-relative" cdkDropListGroup>
<!--  <ngx-dropdown-treeview [config]="config" [headerTemplate]="headerTemplate" [items]="items"-->
<!--                         [itemTemplate]="itemTemplate">-->
<!--  </ngx-dropdown-treeview>-->
  <ngx-treeview [hasDrag]="hasDrag" #treeviewComponent [config]="config" [headerTemplate]="headerTemplate" [items]="itemLocal" [showContextMenu]="showContextMenu"
                [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)"
                (dropChange)="drop($event)"
                (filterChange)="onFilterChange($event)" ></ngx-treeview>
  <!--  <label title="Xóa" style="position: absolute; right: 26px; top: 7px" (click)="clearData()" *ngIf="value">x</label>-->
</div>
